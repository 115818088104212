<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ title }}
        <span class="sub">{{ user }}</span>
      </p>
      <div class="log" v-if="data.length > 0">
        <div v-for="(row, index) in data" :key="index" class="log-row">
          <span class="log-span d-flex align-center">
            {{ row.content }}
          </span>
          <div class="d-flex align-center">
            <span>
              {{ time.makeLocalTime(row.date, 'min') }}
            </span>
            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="contentsView(row.no)"
                >
                  <v-icon color="grey lighten-1">mdi-arrow-right-circle</v-icon>
                </v-btn>
              </template>
              <span>바로가기</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="log" v-else>
        <p class="log-row mr-16"><span>{{ title }}이 없습니다.</span></p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgCustomerHistory',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
      required: true,
    },
    user: {
      type: String,
    },
    command: {
      type: String,
    },
    func: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    comm,
    time,
    groups: [],
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      post: 'dialog/post',
      sale: 'dialog/sale',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.func();
    },
    contentsView(no) {
      this.progress(true);
      this.$socket.emit(this.command, { no }, (resp) => {
        if (resp.result === 'success') {
          if (this.command !== 'sales.get') {
            this.post({
              show: true,
              type: 0,
              func: () => false,
              item: { ...resp.item },
            });
          } else {
            this.sale({
              show: true,
              func: () => false,
              item: { ...resp.item },
            });
          }
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    // getGroupList() {
    //   this.$socket.emit('groups.list.get', {
    //     page: 1,
    //     itemsPerPage: 0,
    //     sortBy: ['no'],
    //     sortDesc: [false],
    //     filters: [],
    //   }, (resp) => {
    //     if (resp.result === 'success') {
    //       this.groups = resp.items.groups;
    //     } else {
    //       this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
    //       console.error(resp);
    //       this.init();
    //     }
    //   });
    // },
  },
  watch: {
    // show: {
    //   handler(val) {
    //     if (val === true) this.getGroupList();
    //   },
    // },
  },
};
</script>

<style scoped lang="scss">
.log-wrap {
  padding:15px;
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin:0;
  }
  .sub {
    font-size: .9rem;
    margin-top: -8px;
    margin-bottom:6px;
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding:10px;
    .log-row {
      margin:0;
      display: flex;
      justify-content: space-between;
      &+.log-row{
        margin-top:4px;
      }
      span {
        font-size: .8rem;
        color: #555;
        &+div {
          margin-left: 60px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
</style>
